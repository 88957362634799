
// Button Styles

.btn {
  padding: 8px 16px;
  font-weight: 700;
  font-size:16px;
  line-height: 24px;
  border-radius: 30px;
  transition: none;
  border: 1px solid transparent;

  i {
    margin: 0 8px;
  }
  i.portal {
    margin: 0 16px;
  }
  @each $theme in map-keys($themes) 
  {
    &.btn-#{$theme} {
      position: relative;
      @if $theme == dark-pink or $theme == medium-green or $theme == dark-purple {
        color: $white;
      } @else {
        color: $black;
      }

      background-color: map-get($themes, $theme);
      overflow: hidden;

      span {
        position: relative;
        display: block;
        z-index: 1;
      }
      &:after {
        content: ' ';
        position: absolute;
        width: 0;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        background-color: darken(map-get($themes, $theme), 5%);
      }
      &.outline {
        background: none;
        color: $white;
        border: 2px solid !important;
        border-color: map-get($themes, $theme) !important;

        &.dark-text {
          color: $black;
        }
        &:hover,
        &:focus {
          border-color: lighten(map-get($themes, $theme), 30%) !important;

          span {
            position: relative;
            display: block;
            z-index: 1;
          }
          &:after {
            background-color: map-get($themes, $theme);
            opacity: .10;
            z-index: 0;
          }
        }
      }
      &:hover,
      &:focus {
        border: 1px solid darken(map-get($themes, $theme), 5%);
        transition: all 100ms ease-in-out;
        @if $theme == dark-pink or $theme == medium-green {
          color: $white;
        } @else {
          color: $black;
        }

        &:after {
          width: 100%;
          opacity: .5;
          transition: all 150ms ease-in-out;
          z-index: 0;
        }
      }
    }
  }

  &.btn-circle-md {
    width: 64px;
    height: 64px;
    border-radius: 32px;
  }

}