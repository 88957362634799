//
// Blueprint of Life Marketing Site/Login Pages Styling Setup
//

// Mixins
@import 'mixins/mixins';

// Colors
@import 'variables/colors';

// Fonts
@import 'variables/fonts';

// Bootstrap
@import '../../node_modules/bootstrap/scss/bootstrap';

// Themes
$themes: (
   light-blue: $brand-light-blue,
   medium-blue: $brand-medium-blue,
   dark-blue: $brand-dark-blue,
   light-green: $brand-light-green,
   medium-green: $brand-medium-green,
   dark-green: $brand-dark-green,
   light-pink: $brand-light-pink,
   medium-pink: $brand-medium-pink,
   dark-pink: $brand-dark-pink,
   light-purple: $brand-light-purple,
   medium-purple: $brand-medium-purple,
   dark-purple: $brand-dark-purple,
   light-yellow: $brand-light-yellow,
   medium-yellow: $brand-medium-yellow,
   dark-yellow: $brand-dark-yellow,
);

$grid-breakpoints: (
     xs: 0,
     sm: 576px,
     md: 768px,
     lg: 992px,
     xl: 1200px,
     xxl: 1400px
);

// Default Values
$default-font-family: $poppins-font;
$default-font-size: 18px;
$default-font-weight: 400;
$default-line-height: 27px;
$default-font-color: $black;

* {
   -webkit-font-smoothing: antialiased;
}

html,
body {
   margin: 0;
   padding: 0;
   width: 100%;
   font-family: $default-font-family;
   font-size: $default-font-size;
   font-weight: $default-font-weight;
   line-height: $default-line-height;
   background: $brand-dark-blue url('../images/background.png');

}


// Base
@import 'base/header';
@import 'base/footer';

// Layout
@import 'layout/container';
@import 'layout/guest';

// Modular
@import 'modules/navigation';


// Elements
//@import 'elements/alerts';
@import 'elements/button';
@import 'elements/card';