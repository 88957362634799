@mixin gradient-vertical($startColor: #555, $endColor: #333) {
  background-color: mix($startColor, $endColor, 60%);
  background-image: -moz-linear-gradient(top, $startColor, $endColor); // FF 3.6+
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from($startColor), to($endColor)); // Safari 4+, Chrome 2+
  background-image: -webkit-linear-gradient(top, $startColor, $endColor); // Safari 5.1+, Chrome 10+
  background-image: -o-linear-gradient(top, $startColor, $endColor); // Opera 11.10
  background-image: linear-gradient(to bottom, $startColor, $endColor); // Standard, IE10
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($startColor)}', endColorstr='#{ie-hex-str($endColor)}', GradientType=0); // IE9 and down
}

@mixin placeholder($color, $size:"") {
  &::-webkit-input-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
  }
  &:-moz-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
  }
  &::-moz-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
  }
  &:-ms-input-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
  }
}
