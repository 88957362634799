
// Footer

footer {

  .footer-main {
    padding: 40px 0;
    background-color: $brand-gray;
  }
  .footer-secondary {
    padding: 24px 0;
    background-color: $brand-gray;
    text-align: center;

    @media all and (min-width: 992px) {
      text-align: left;
    }
    .nav-list {

      .nav-item {
        flex-basis: 100% !important;

        @media all and (min-width: 768px) {
          flex-basis: auto !important;
        }
        &:last-child {
          margin: 0 !important;
  
          .nav-link {
            padding: 12px 0;

            @media all and (min-width: 768px) {
              padding: 12px 32px;
            }
          }
        }
      }
    }
  }
  .footer-nav {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media all and (min-width: 992px) {
      flex-direction: row;
    }
    .footer-branding {
      margin-bottom: 16px;
      max-width: 60px;

      @media all and (min-width: 768px) {
        margin-bottom: 16px;
        max-width: 275px;
      }
      @media all and (min-width: 992px) {
        margin-right: auto;
        margin-bottom: 0;
      }
    }
    .nav-list {
      display: flex;
      margin: 0;
      padding: 0;
      list-style: none;
      align-items: center;

      @media all and (max-width: 767px) {
        flex-wrap: wrap;
      }
      .nav-item {
        margin: 0;
        flex-basis: 100%;

        @media all and (max-width: 767px) {
          flex-basis: 50%;

          .nav-link {
            margin: 8px 0;
          }
        }
        @media all and (max-width: 320px) {
          flex-basis: 100%;
        }
        .nav-link {
          display: block;
          padding: 12px 32px;
          font-weight: 400;
          font-size: $default-font-size - 2;
          line-height: 1;
          color: $white;
          text-align: center;
          
          &:hover,
          &:focus {
            color: lighten($brand-gray, 10%);
          }
        }
        &:last-child {
          @media all and (max-width: 767px) {
            margin-top: 24px;
            flex-basis: 100%;
            text-align: center;
          }
          @media all and (min-width: 768px) {
            margin-left: 24px;
          }
        }
        .btn {
          padding: 16px 24px;
          font-weight: 400;
          font-size: $default-font-size - 6;
          line-height: 1;
          color: $white;

          i {
            margin: 0 8px 0 0;
          }
          &:hover,
          &:focus {
            border-color: $brand-gray !important;
  
            &:after {
              background-color: $white !important;
            }
          }
        }
      }
    }
    .contact-info {
      max-width: 550px;
      padding: 0 14px;
      margin-bottom: 16px;

      @media all and (min-width: 992px) {
        margin-right: auto;
        margin-bottom: 0;
        padding: 0;
      }
      .title {
        margin-bottom: 0;
        font-family: $din-condensed-font;
        font-size: $default-font-size - 2;
        line-height: $default-line-height - 2;
        color: $white;
      }
      address {
        margin: 0;
        font-size: $default-font-size - 6;
        line-height: $default-line-height - 6;
        color: $white;
      }
    }
  }
}