// Card Styles


.card {

  border-radius: 10px;
  padding: 24px;

  &.card-login {
    margin: 16px 16px 48px;
    border-radius: 10px;
    padding: 0;
  }

  &.card-lessons {
    border-radius: 10px 10px 0 0;
    padding: 16px 24px;

    @media (min-width: 576px) {
      padding: 24px 32px;
    }
  }

  .section-detail {
    margin-right:48px;
    font-size: $default-font-size;
    line-height: $default-line-height - 3px;
    font-weight: $default-font-weight + 300;
    color: #333;

    .text {
      margin-left: 12px;
    }
  }

  &.complete{
    //background-color: rgba(#09CC12, 10%);
    background: linear-gradient(0deg, rgba(9, 204, 18, 0.1), rgba(9, 204, 18, 0.1)), #FFFFFF;

    .badge {
      padding: 0;

      .fa-badge-check {
        margin-right: 4px;
        color: $brand-dark-green;
      }
    }
  }
}

.card-complete {
  border-radius: 10px;
  background-color: #FFF;
}