
// Guest Layout Styles

main {
  position: relative;
  z-index: 1;
}

.logo {
  margin-top: 85px;
  width: 157px;
  cursor: pointer;
}

.chromosome {
  position: absolute;
  z-index: 0;

  &.chromosome-white {
    background: url(../../images/chromosome-white.png) no-repeat;
    z-index: 1;
    width: 98px;
    height: 112px;
    top: 25%;
    left: 5%;
  }
  &.chromosome-pink {
    background: url(../../images/chromosome-pink.png) no-repeat;
    width: 147px;
    height: 169px;
    top: 10%;
    left: 50%;
  }
  &.chromosome-green {
    background: url(../../images/chromosome-green.png) no-repeat;
    width: 107px;
    height: 123px;
    bottom: 5%;
    right: 50%;
  }
  &.chromosome-purple {
    background: url(../../images/chromosome-purple.png) no-repeat;
    width: 211px;
    height: 212px;
    bottom: 5%;
    right: 5%;
  }

}

.guest-pic {

  z-index: 1;
}

.vector-shape {
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 0;
  width: 494px;
  height: 806px;
  background: url(../../images/vector-shape.png) no-repeat;
}

.text-sm {
  font-size:16px;
  line-height:24px;
  font-weight: 700;
}

.forgot {
  font-family: $din-condensed-font;
  font-size:14px;
  line-height:18px;
  text-decoration: none;
}

// Use placeholder mixin (size parameter optional)
[placeholder] {
  @include placeholder(#ABB5BE, 16px);
}