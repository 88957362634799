
// Base Colors
$white:                       #ffffff;               // White
$black:                       #000000;               // Black

// Brand Colors
$brand-light-blue:            #197FF1;                // Brand Light Blue
$brand-medium-blue:           #0B35CE;                // Brand Medium Blue
$brand-dark-blue:             #00319E;                // Brand Dark Blue

$brand-light-green:           #35DC84;                // Brand Light Green
$brand-medium-green:          #1BEF76;                // Brand Medium Green
$brand-dark-green:            #09CC12;                // Brand Dark Green

$brand-light-pink:            #FF56C7;                // Brand Light Pink
$brand-medium-pink:           #F92BBE;                // Brand Medium Pink
$brand-dark-pink:             #AF0C89;                // Brand Dark Pink

$brand-light-purple:          #BE5AF6;                // Brand Light Purple
$brand-medium-purple:         #9315E5;                // Brand Medium Purple
$brand-dark-purple:           #600BB2;                // Brand Dark Purple

$brand-light-yellow:          #FCD842;                // Brand Light Yellow
$brand-medium-yellow:         #F4CB00;                // Brand Medium Yellow
$brand-dark-yellow:           #F29B00;                // Brand Dark Yellow

$brand-off-white:             #F1F1F1;                // Brand Off White

$brand-gray:                  #DADADA;                // Brand Gray
$brand-dark-gray:             #767676;

