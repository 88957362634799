
// Main Navigation

.navigation {

  .navbar {
    padding: 40px 16px;
    z-index: 5;

    .navbar-toggler {
      display: none;
      margin: 0;
      padding: 16px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-color: transparent;

      &:focus {
        outline: none;
        box-shadow: none;
      }
      &.collapsed {
        color: rgba(0,0,0,.55);
        border-color: rgba(0,0,0,.1);

        .icon-bar {

          &:nth-of-type(1) {
            transform: rotate(0);
          }
          &:nth-of-type(2) {
            opacity: 1;
          }
          &:nth-of-type(3) {
            transform: rotate(0);
          }
        }
      }
      .icon-bar {
        display: block;
        width: 22px;
        height: 2px;
        border-radius: 1px;
        background-color: $brand-gray;
        transition: all 0.2s;

        & + .icon-bar {
          margin-top: 4px;
        }
        &:nth-of-type(1) {
          transform: rotate(45deg);
          transform-origin: 10% 10%;
        }
        &:nth-of-type(2) {
          opacity: 0;
        }
        &:nth-of-type(3) {
          transform: rotate(-45deg);
          transform-origin: 10% 90%;
        }
      }
      @media all and (max-width: 991px) {
        display: block;
      }
    }
    .navbar-collapse {
      align-self: center!important;

      @media all and (max-width: 991px) 
      {
        .navbar-nav {
          margin: 40px 0 24px;
          padding-top: 40px;
          border-top: 1px solid lighten($brand-gray, 15%);

          .megamenu {
            position: relative;
            overflow: hidden;
          }
          .nav-item {

            & + .nav-item {
              margin-top: 8px;
            }
          }
          .nav-link {
            border-color: lighten($brand-gray, 15%);
            justify-content: space-between !important;

            &.show {
              color: $white !important;
              background-color: $brand-gray;

              .fa-caret-up {
                color: $white;
              }
            }
          }
        }
        .btn-catalog {
          justify-content: center !important;
        }
      }
    }
    .dropdown-toggle {
      display: flex;
      position: relative;
      padding: 8px 24px;
      font-weight: 600;
      border: 2px solid $white;
      border-radius: 24px;
      color: $brand-gray;

      &:after {
        position: absolute;
        margin: 0 0 0 -16px;
        padding: 0;
        width: 32px;
        height: 32px;
        top: 70px;
        left: 50%;
        border: none;
        border-radius: 50%;
        background-color: darken($brand-gray, 10%);
        opacity: 0;
      }
      &.active {
        color: $white;
        background-color: $brand-light-blue;

        .fa-caret-up,
        .fa-caret-down {
          color: $white;
        }
        &.show {
          color: $brand-gray;
          background: none;
          border: 2px solid $brand-light-blue;

          i {
            color: $brand-gray;
          }
        }
      }
      &.show {

        .fa-caret-up {
          display: flex;
        }
        .fa-caret-down {
          display: none;
        }
      }
      .fa-caret-up,
      .fa-caret-down {
        margin-left: 16px;
        align-self: center !important;
      }
      .fa-caret-up {
        display: none;
        color: $brand-gray;
      }
      .fa-caret-down {
        display: flex;
        color: $brand-light-blue;
      }
    }
    &.navbar-light,
    &.bg-light {
      background-color: $white !important;
    }
    .navbar-brand {
      margin: 0;
      padding: 0;
      max-width: 152px;

      @media all and (min-width: 426px) {
        max-width: 246px;
      }
    }
    @media all and (min-width: 992px) {
      .has-megamenu {
        position: static !important;
      }
      .dropdown-toggle {

        &.show {
          
          &:after {
            transition-delay: 50ms !important;
            transition: opacity .15s;
            opacity: 1;
          }
        }
      }
      .megamenu {
        left: 0;
        right: 0;
        width: 100%;
        margin-top: 0;
      }
    }
  }
}